import { HttpClient, ListResponse } from '@amzn/dots-core-ui';
import { Group } from '../models';

export interface GroupApi {
  getAll: () => Promise<ListResponse<Group>>;
}

export const groups = (client: HttpClient): GroupApi => ({
  getAll: (): Promise<ListResponse<Group>> => client.get({ url: '/group' }),
});
