import { HttpClient, TokenCreator } from '@amzn/dots-core-ui';
import { AttributeApi, attributes } from './attributes';
import { ConfigurationApi, configurations } from './configurations';
import { CreatorApi, creators } from './creators';
import { FeatureApi, features } from './features';
import { GroupApi, groups } from './groups';
import { PackageApi, packages } from './packages';
import { PlanApi, plans } from './plans';
import { SourceApi, sources } from './sources';
import { VersionApi, versions } from './versions';

export interface TmsApi {
  attributes: AttributeApi;
  configurations: ConfigurationApi;
  creators: CreatorApi;
  features: FeatureApi;
  groups: GroupApi;
  packages: PackageApi;
  plans: PlanApi;
  sources: SourceApi;
  versions: VersionApi;
}

export const createTmsClient = (
  tmsApiUrl: string,
  createToken: TokenCreator
): TmsApi => {
  const client = new HttpClient(tmsApiUrl, createToken);
  return {
    attributes: attributes(client),
    configurations: configurations(client),
    creators: creators(client),
    features: features(client),
    groups: groups(client),
    packages: packages(client),
    plans: plans(client),
    sources: sources(client),
    versions: versions(client),
  };
};
