import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { CoreToasterProvider } from '@amzn/dots-core-ui';
import Home from './Home';
import { ConfigurationList } from './Configurations';
import { PackageDetail } from './Packages';
import { PlanDetail, PlanList } from './Plans';
import { rootPath } from './routes';

export type AppRouterProps = {
  isRootApp: boolean;
};

export default ({ isRootApp }: AppRouterProps): JSX.Element => {
  return (
    <CoreToasterProvider>
      <Switch>
        <Route path={`${rootPath}/packages/:id`}>
          <PackageDetail />
        </Route>
        <Route path={`${rootPath}/packages`}>
          <ConfigurationList title="Test Suites" packageType="test" />
        </Route>
        <Route path={`${rootPath}/resources/:id`}>
          <PackageDetail />
        </Route>
        <Route path={`${rootPath}/resources`}>
          <ConfigurationList title="Resources" packageType="resource" />
        </Route>
        <Route path={`${rootPath}/handlers/:id`}>
          <PackageDetail />
        </Route>
        <Route path={`${rootPath}/handlers`}>
          <ConfigurationList title="Handlers" packageType="handler" />
        </Route>
        <Route path={`${rootPath}/plans/:id`}>
          <PlanDetail />
        </Route>
        <Route path={`${rootPath}/plans`}>
          <PlanList />
        </Route>
        <Route path={rootPath} component={Home} />
        {isRootApp && <Redirect to={rootPath} />}
      </Switch>
    </CoreToasterProvider>
  );
};
