import React, { useState } from 'react';
import Button from '@amzn/meridian/button';
import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import Loader from '@amzn/meridian/loader';
import Text from '@amzn/meridian/text';
import Theme from '@amzn/meridian/theme';
import orangeDarkTokens from '@amzn/meridian-tokens/theme/orange-dark';

import Icon from '@amzn/meridian/icon';
import alertTokens from '@amzn/meridian-tokens/base/icon/alert';
import {
  CoreInput,
  CoreConfirmationCheckbox,
  isNullOrWhitespace,
  useForm,
} from '@amzn/dots-core-ui';
import { Package, useClient } from '../../api';

export type PackageDeleteFormProps = {
  p?: Package;
  onSubmit: (p: Partial<Package>) => void;
  onCancel: () => void;
};

export default ({
  p,
  onSubmit,
  onCancel,
}: PackageDeleteFormProps): JSX.Element => {
  const { tmsClient } = useClient();
  const { values, errors, handlers, hasErrors, isSubmitting, handleSubmit } =
    useForm({
      name: {
        initialValue: p?.name ?? '',
        validate: async (value): Promise<string> => {
          if (isNullOrWhitespace(value)) {
            return 'Name is required';
          }
          if (value === p?.name) {
            return '';
          }
          const result = await tmsClient.packages.getAll({ name: value });
          if (result.count > 0) {
            return `${value} is already in use`;
          }
          return '';
        },
      },
      confirmation: {
        initialValue: '',
        validate: (value): string =>
          value !== `delete ${p?.name}`
            ? 'A written confirmation is required.'
            : '',
      },
      acknowledgementTms: {
        initialValue: false,
        validate: (value): string =>
          !value ? 'An acknowledgement is required' : '',
      },
      acknowledgementDts: {
        initialValue: false,
        validate: (value): string =>
          !value ? 'An acknowledgement is required' : '',
      },
    });
  const [checkedTms, setCheckedTms] = useState(false);
  const [checkedDts, setCheckedDts] = useState(false);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Column spacingInset="xsmall" alignmentHorizontal="stretch">
        <Text>
          <Icon tokens={alertTokens} />
          <br />
          <br />
          This will be a permanent change and will also delete the underlying
          test suites and versions.
          <br />
          To delete this resource, type <strong>
            delete {values.name}
          </strong>{' '}
          below.
        </Text>
        <CoreInput
          value={values.confirmation}
          errorMessage={errors.confirmation}
          placeholder={`delete ${values.name}`}
          onChange={handlers.confirmation}
        />
        <CoreConfirmationCheckbox
          value={values.acknowledgementTms}
          errorMessage={errors.acknowledgementTms}
          onChange={(e) => {
            setCheckedTms(e);
            handlers.acknowledgementTms(e);
          }}
          checked={checkedTms}
          size="large"
        >
          I understand that this will permanently delete the resource, its test
          suites and versions.
        </CoreConfirmationCheckbox>
        <CoreConfirmationCheckbox
          value={values.acknowledgementDts}
          errorMessage={errors.acknowledgementDts}
          onChange={(e) => {
            setCheckedDts(e);
            handlers.acknowledgementDts(e);
          }}
          checked={checkedDts}
          size="large"
        >
          I understand that this might affect pending testruns and rules.
        </CoreConfirmationCheckbox>
        <Row
          alignmentHorizontal="right"
          alignmentVertical="center"
          widths="fit"
        >
          <Button type="secondary" size="small" onClick={onCancel}>
            Cancel
          </Button>
          <Theme tokens={orangeDarkTokens}>
            <Button
              type="primary"
              size="small"
              disabled={hasErrors || isSubmitting}
              submit
            >
              {isSubmitting ? (
                <Loader type="circular" size="small" />
              ) : (
                'Confirm'
              )}
            </Button>
          </Theme>
        </Row>
      </Column>
    </form>
  );
};
