import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import SideMenu, {
  SideMenuTitle,
  SideMenuLink,
  SideMenuSpacer,
} from '@amzn/meridian/side-menu';
import helpIconTokens from '@amzn/meridian-tokens/base/icon/help';
import starIconTokens from '@amzn/meridian-tokens/base/icon/star';
import Heading from '@amzn/meridian/heading';
import { rootPath } from './routes';

export default ({ isMenuOpen }: { isMenuOpen: boolean }): JSX.Element => {
  const history = useHistory();
  const location = useLocation();
  const pathStartsWith = (test: string): boolean =>
    location.pathname.substring(0, test.length) === test;
  return (
    <SideMenu width={225} open={isMenuOpen}>
      <SideMenuTitle href={rootPath} onClick={history.push}>
        <Heading level={4}>Test Management</Heading>
      </SideMenuTitle>
      <SideMenuLink
        key="Favorites"
        href={`${rootPath}`}
        onClick={history.push}
        selected={location.pathname === `${rootPath}`}
        icon={starIconTokens}
      >
        <span>Favorites</span>
      </SideMenuLink>
      {[
        { title: 'Test Suites', path: `${rootPath}/packages` },
        { title: 'Plans', path: `${rootPath}/plans` },
        { title: 'Resources', path: `${rootPath}/resources` },
        { title: 'Handlers', path: `${rootPath}/handlers` },
      ].map((item) => (
        <SideMenuLink
          key={item.title}
          href={item.path}
          onClick={history.push}
          selected={pathStartsWith(item.path)}
        >
          <span>{item.title}</span>
        </SideMenuLink>
      ))}
      <SideMenuSpacer />
      <SideMenuLink
        href="https://wiki.labcollab.net/confluence/display/DOTS/TMS+User+Guide"
        target="_blank"
        icon={helpIconTokens}
      >
        <span>User Guide</span>
      </SideMenuLink>
    </SideMenu>
  );
};
