import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  CoreListPage,
  useToggle,
  ListResponse,
  configurationSearchFields,
  TmsSearchScope,
  CoreModal,
} from '@amzn/dots-core-ui';
import { Configuration, PackageType, useClient } from '../../api';
import ConfigurationColumnDefinitions from './ConfigurationColumnDefinitions';
import { PackageForm } from '../Packages';

const ConfigurationList = ({
  title,
  packageType,
}: {
  title: string;
  packageType: PackageType;
}): JSX.Element => {
  const history = useHistory();
  const { path } = useRouteMatch();
  const [isModalOpen, closeModal, openModal] = useToggle();
  const { tmsClient, searchClient } = useClient();
  const scope: TmsSearchScope = {
    model: 'config',
    parameters: { packageType },
  };
  const resolver = (ids: number[]): Promise<ListResponse<Configuration>> =>
    tmsClient.configurations.getAll({
      ids,
      page: 0,
      pageSize: 0,
    });
  return (
    <>
      <CoreModal
        title={`Add a new ${packageType}`}
        isOpen={isModalOpen}
        onClose={closeModal}
      >
        <PackageForm
          onCancel={closeModal}
          onSubmit={async (newPackage): Promise<void> => {
            const result = await tmsClient.packages.create({
              ...newPackage,
              type: packageType,
            });
            history.push(`${path}/${result.id}/suites`);
          }}
        />
      </CoreModal>
      <CoreListPage
        title={title}
        columnDefinitions={ConfigurationColumnDefinitions}
        getItemId={(item: Configuration): number => item.id}
        filters={configurationSearchFields}
        scope={scope}
        searchClient={searchClient}
        resolver={resolver}
        onAddButtonClick={openModal}
        onRowClick={(item: Configuration): void =>
          history.push(
            `${path}/${item.packageId}/${
              packageType === 'test' ? `suites#${item.name}` : 'versions'
            }`
          )
        }
      />
    </>
  );
};
export default ConfigurationList;
