import {
  PaginatedResponse,
  ListResponse,
  HttpClient,
} from '@amzn/dots-core-ui';
import { Version, VersionUploadResponse } from '../models';

export interface VersionApi {
  getAll: (filters: { packageId?: number }) => Promise<ListResponse<Version>>;
  getPage: (filters: {
    packageId?: number;
    page?: number;
    pageSize?: number;
  }) => Promise<PaginatedResponse<Version>>;
  getDownloadUrl: (version: Version) => string;
  createExternalVersion: (
    packageId: number,
    url: string,
    description: string
  ) => Promise<Version>;
  createTmsVersion: (
    packageId: number,
    file: File,
    description: string
  ) => Promise<Version>;
  update: (id: number, version: Partial<Version>) => Promise<Version>;
  remove: (id: number) => Promise<void>;
}

export const versions = (client: HttpClient): VersionApi => ({
  getAll: ({
    packageId,
  }: {
    packageId?: number;
  }): Promise<ListResponse<Version>> =>
    client.get({
      url: '/version',
      payload: { packageId, page: 0, pageSize: 0 },
    }),
  getPage: ({
    packageId,
    page = 1,
    pageSize = 25,
  }: {
    packageId?: number;
    page?: number;
    pageSize?: number;
  }): Promise<PaginatedResponse<Version>> =>
    client.get({ url: '/version', payload: { packageId, page, pageSize } }),
  getDownloadUrl: (version: Version): string =>
    `${client.baseUrl}/version/${version.id}/download`,
  createExternalVersion: (
    packageId: number,
    url: string,
    description: string
  ): Promise<Version> =>
    client.post({ url: '/version', payload: { description, url, packageId } }),
  createTmsVersion: async (
    packageId: number,
    file: File,
    description: string
  ): Promise<Version> => {
    const urlResponse = await client.put<VersionUploadResponse>({
      url: '/version/upload',
      payload: {
        packageId,
        description,
        fileName: file.name,
      },
    });
    await client.request({
      url: urlResponse.location,
      method: 'put',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: file,
    });
    return client.post({
      url: '/version',
      payload: {
        packageId,
        description,
        fileName: file.name,
        fileSize: file.size,
        s3Key: urlResponse.s3Key,
      },
    });
  },
  update: (id: number, version: Partial<Version>): Promise<Version> =>
    client.put({ url: `/version/${id}`, payload: { ...version, id } }),
  remove: (id: number): Promise<void> =>
    client.delete({ url: `/version/${id}` }),
});
