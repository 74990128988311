import React from 'react';
import Link from '@amzn/meridian/link';
import Text from '@amzn/meridian/text';
import {
  CoreProperties,
  CorePropertyRow,
  CorePropertyColumn,
  CorePropertyDetail,
  CoreDate,
} from '@amzn/dots-core-ui';
import { useHistory } from 'react-router-dom';
import { Plan, PlanType } from '../../api';
import { rootPath } from '../routes';

export type PlanPropertiesProps = { plan: Plan; planType: PlanType };

export const PlanProperties = ({
  plan,
  planType,
}: PlanPropertiesProps): JSX.Element => {
  const history = useHistory();
  return (
    <CoreProperties>
      <CorePropertyRow title="Details">
        <CorePropertyColumn>
          {plan.createdBy && (
            <CorePropertyDetail label="created by">
              <Link
                onClick={history.push}
                href={`${rootPath}/plans?q=${plan.createdBy}&filters=createdBy`}
              >
                {plan.createdBy}
              </Link>
            </CorePropertyDetail>
          )}
          {plan.created && (
            <CorePropertyDetail label="created">
              <Text>
                <CoreDate date={plan.created} />
              </Text>
            </CorePropertyDetail>
          )}
        </CorePropertyColumn>
        <CorePropertyColumn>
          {plan.modified && (
            <CorePropertyDetail label="modified">
              <Text>
                <CoreDate date={plan.modified} />
              </Text>
            </CorePropertyDetail>
          )}
        </CorePropertyColumn>
      </CorePropertyRow>
      {planType === 'dynamic' && (
        <CorePropertyRow title="Search Parameters">
          <CorePropertyColumn>
            <CorePropertyDetail label="params">
              <Text>
                <code>{plan.searchParams}</code>
              </Text>
            </CorePropertyDetail>
          </CorePropertyColumn>
        </CorePropertyRow>
      )}
    </CoreProperties>
  );
};
