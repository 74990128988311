import { ColumnDefinition } from '@amzn/dots-core-ui';
import { Configuration } from '../../api';

const ConfigurationColumnDefinitions: ColumnDefinition<Configuration>[] = [
  { id: 'id', header: 'ID', item: (item): number => item.id },
  {
    id: 'name',
    header: 'Name',
    item: (item): string => item.name,
  },
  {
    id: 'feature',
    header: 'Feature',
    item: (item): string => item.featureName,
  },
  { id: 'owner', header: 'Owner', item: (item): string => item.packageOwner },
  {
    id: 'users',
    header: 'Users',
    item: (item): string => item.packageUsers.join(', '),
  },
];

export default ConfigurationColumnDefinitions;
