import React from 'react';
import Button from '@amzn/meridian/button';
import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import Loader from '@amzn/meridian/loader';
import {
  CoreFileInput,
  CoreTextarea,
  CoreInput,
  isNullOrWhitespace,
  useForm,
} from '@amzn/dots-core-ui';
import { SourceType, Version } from '../../api';

export type VersionFormProps = {
  sourceType?: SourceType;
  version?: Version;
  onSubmit: (fields: {
    file?: File;
    url?: string;
    description: string;
  }) => void;
  onCancel: () => void;
};

export const VersionForm = ({
  sourceType,
  version,
  onSubmit,
  onCancel,
}: VersionFormProps): JSX.Element => {
  const {
    values,
    errors,
    handlers,
    handleSubmit,
    hasErrors,
    isSubmitting,
  } = useForm({
    file: {
      initialValue: [],
      validate: (value: File[]): string =>
        (version?.packageSource === 'TMS' || sourceType === 'TMS') &&
        value.length === 0 &&
        version === undefined
          ? 'File is required'
          : '',
    },
    url: {
      initialValue: version?.url ?? '',
      validate: (value): string => {
        if (version?.packageSource === 'KBITS' || sourceType === 'KBITS') {
          if (isNullOrWhitespace(value)) {
            return 'KBITS Url is required';
          }
        }
        return '';
      },
    },
    description: {
      initialValue: version?.description ?? '',
    },
  });

  const showFilePicker = version === undefined && sourceType === 'TMS';

  const showUrlInput =
    version?.packageSource === 'KBITS' || sourceType === 'KBITS';

  return (
    <form
      onSubmit={handleSubmit((v) =>
        onSubmit({ file: v.file[0], description: v.description, url: v.url })
      )}
    >
      <Column spacingInset="xsmall" alignmentHorizontal="stretch">
        {showFilePicker && (
          <CoreFileInput
            label="File"
            errorMessage={errors.file}
            onChange={handlers.file}
            disabled={isSubmitting}
          />
        )}
        {showUrlInput && (
          <CoreInput
            label="KBITS Url"
            type="url"
            value={values.url}
            errorMessage={errors.url}
            onChange={handlers.url}
            disabled={version !== undefined || isSubmitting}
          />
        )}
        <CoreTextarea
          label="Description"
          value={values.description}
          rows={3}
          onChange={handlers.description}
          disabled={isSubmitting}
        />
        <Row
          alignmentHorizontal="right"
          alignmentVertical="center"
          widths="fit"
        >
          <Button type="secondary" size="small" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            type="primary"
            size="small"
            disabled={hasErrors || isSubmitting}
            submit
          >
            {isSubmitting ? <Loader type="circular" size="small" /> : 'Confirm'}
          </Button>
        </Row>
      </Column>
    </form>
  );
};
