import React from 'react';
import { useQuery } from 'react-query';
import Button from '@amzn/meridian/button';
import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import Loader from '@amzn/meridian/loader';
import Toggle from '@amzn/meridian/toggle';
import Text from '@amzn/meridian/text';
import {
  CoreInput,
  CoreTextarea,
  CoreFilterableSelect,
  isNullOrWhitespace,
  useForm,
} from '@amzn/dots-core-ui';
import Alert from '@amzn/meridian/alert';
import { Package, useClient } from '../../api';

export type PackageFormProps = {
  p?: Package;
  onSubmit: (p: Partial<Package>) => void;
  onCancel: () => void;
};

export default ({ p, onSubmit, onCancel }: PackageFormProps): JSX.Element => {
  const { tmsClient } = useClient();
  const {
    values,
    errors,
    handlers,
    hasErrors,
    isSubmitting,
    handleSubmit,
  } = useForm({
    name: {
      initialValue: p?.name ?? '',
      validate: async (value): Promise<string> => {
        if (isNullOrWhitespace(value)) {
          return 'Name is required';
        }
        if (value === p?.name) {
          return '';
        }
        const result = await tmsClient.packages.getAll({ name: value });
        if (result.count > 0) {
          return `${value} is already in use`;
        }
        return '';
      },
    },
    description: {
      initialValue: p?.description ?? '',
    },
    featureName: {
      initialValue: p?.featureName ?? '',
      validate: (value): string =>
        isNullOrWhitespace(value) ? 'Feature is required' : '',
    },
    source: {
      initialValue: p?.source ?? '',
      validate: (value): string =>
        isNullOrWhitespace(value) ? 'Source is required' : '',
    },
    owner: {
      initialValue: p?.owner ?? '',
      validate: (value): string =>
        isNullOrWhitespace(value) ? 'Owner is required' : '',
    },
    users: {
      initialValue: p?.users ?? [],
      validate: (value): string =>
        value.length === 0 ? 'Users are required' : '',
    },
    config: {
      initialValue: p?.config ?? '',
    },
    archived: {
      initialValue: p?.archived ?? false,
    },
  });

  const groups = useQuery('groups', async () => {
    const response = await tmsClient.groups.getAll();
    return response.content.map((item) => item.name);
  });
  const sources = useQuery('sources', async () => {
    const response = await tmsClient.sources.getAll();
    return response.content.map((item) => item.name);
  });
  const features = useQuery('features', async () => {
    const response = await tmsClient.features.getAll();
    return response.content.map((item) => item.name);
  });

  if (groups.isLoading || sources.isLoading || features.isLoading) {
    return (
      <Column
        minHeight="100%"
        alignmentHorizontal="center"
        alignmentVertical="center"
      >
        <Loader size="large" type="circular" />
      </Column>
    );
  }

  if (groups.isError || sources.isError || features.isError) {
    return (
      <Alert type="error" size="xlarge" title="Whoops, something went wrong">
        error
      </Alert>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Column spacingInset="xsmall" alignmentHorizontal="stretch">
        {(!p || (p && p.type !== 'test')) && (
          <CoreInput
            label="Name"
            value={values.name}
            errorMessage={errors.name}
            type="text"
            onChange={handlers.name}
          />
        )}
        {(!p || (p && p.type === 'handler')) && (
          <CoreTextarea
            label="Configuration"
            value={values.config}
            errorMessage={errors.config}
            onChange={handlers.config}
            rows={3}
          />
        )}
        <CoreTextarea
          value={values.description}
          onChange={handlers.description}
          label="Description"
          rows={3}
        />
        <CoreFilterableSelect
          label="Feature"
          value={values.featureName}
          items={features.data ?? []}
          errorMessage={errors.featureName}
          onChange={handlers.featureName}
        />
        <CoreFilterableSelect
          label="Source"
          value={values.source}
          items={sources.data ?? []}
          errorMessage={errors.source}
          onChange={handlers.source}
          disabled={p?.id !== undefined}
        />
        <div>
          <Text>Archived</Text>
          <Toggle
            name="archived"
            checked={values.archived}
            value={values.archived}
            onChange={handlers.archived}
          />
        </div>
        <CoreFilterableSelect
          label="Owner"
          value={values.owner}
          items={groups.data ?? []}
          errorMessage={errors.owner}
          onChange={handlers.owner}
        />
        <CoreFilterableSelect
          label="Users"
          value={values.users}
          items={groups.data ?? []}
          errorMessage={errors.users}
          onChange={handlers.users}
        />
        <Row
          alignmentHorizontal="right"
          alignmentVertical="center"
          widths="fit"
        >
          <Button type="secondary" size="small" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            type="primary"
            size="small"
            disabled={hasErrors || isSubmitting}
            submit
          >
            {isSubmitting ? <Loader type="circular" size="small" /> : 'Confirm'}
          </Button>
        </Row>
      </Column>
    </form>
  );
};
