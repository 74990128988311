import React, { useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Link from '@amzn/meridian/link';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import {
  CoreAsync,
  CoreModal,
  CoreDetailHeader,
  CoreTabGroupSwitch,
  CoreTabRoute,
  ListResponse,
  useToggle,
  CorePage,
} from '@amzn/dots-core-ui';
import { Plan, PlanType, PlanItem, useClient } from '../../api';
import { PlanForm } from './PlanForm';
import { PlanProperties } from './PlanProperties';
import { PlanItemList } from './PlanItemList';
import { rootPath } from '../routes';

const PlanDetail = (): JSX.Element => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const parsedId = Number(id);
  const [isEditModalOpen, closeEditModal, openEditModal] = useToggle();
  const { tmsClient } = useClient();
  const asyncAction = useCallback(async (): Promise<{
    plan: Plan;
    planType: PlanType;
    items: ListResponse<PlanItem>;
  }> => {
    const [planResponse, itemsResponse] = await Promise.all([
      tmsClient.plans.get(parsedId),
      tmsClient.plans.getItems(parsedId),
    ]);
    return {
      plan: planResponse,
      planType: planResponse.searchParams ? 'dynamic' : 'static',
      items: itemsResponse,
    };
  }, [parsedId]);

  return (
    <CoreAsync action={asyncAction}>
      {(data, fetch): JSX.Element => (
        <CorePage
          header={
            <CoreDetailHeader
              heading={`${data.plan.name} | ${data.plan.id}`}
              subHeading={
                <>
                  {data.plan.description && (
                    <Text>{data.plan.description}</Text>
                  )}
                  {data.plan.owner && (
                    <Row>
                      <Text>Owner:</Text>
                      <Link
                        onClick={history.push}
                        href={`${rootPath}/plans?q=${data.plan.owner}&filters=owner`}
                      >
                        {data.plan.owner}
                      </Link>
                    </Row>
                  )}
                </>
              }
              editEnabled={data.plan.editable}
              onEditClick={openEditModal}
            />
          }
        >
          <CoreModal
            title={`Edit ${data.plan.name}`}
            isOpen={isEditModalOpen}
            onClose={closeEditModal}
          >
            <PlanForm
              plan={data.plan}
              planType={data.planType}
              onCancel={closeEditModal}
              onSubmit={async (updatedPlan): Promise<void> => {
                await tmsClient.plans.update(data.plan.id, updatedPlan);
                closeEditModal();
                fetch();
              }}
            />
          </CoreModal>
          <CoreTabGroupSwitch>
            <CoreTabRoute label="Test Suites" path="suites">
              <PlanItemList
                items={data.items.content}
                plan={data.plan}
                planType={data.planType}
                onUpdate={fetch}
              />
            </CoreTabRoute>
            <CoreTabRoute label="Details" path="details">
              <PlanProperties plan={data.plan} planType={data.planType} />
            </CoreTabRoute>
          </CoreTabGroupSwitch>
        </CorePage>
      )}
    </CoreAsync>
  );
};

export default PlanDetail;
