import React from 'react';
import { BrowserRouter, Router } from 'react-router-dom';
import {
  mountReactComponent,
  unmountReactComponent,
  CoreAuth,
  RemoteLifecyleEventArgs,
  RemoteApplicationAdapter,
} from '@amzn/dots-core-ui';
import '@amzn/meridian-tokens/base/font/amazon-ember.css';
import AppRouter from './features/AppRouter';
import AppNavigation from './features/AppNavigation';
import App from './features/App';
import { environment } from './environments';
import { ClientProvider } from './api';

declare global {
  interface Window {
    TestManagement: RemoteApplicationAdapter;
  }
}

window.TestManagement = {
  mount: ({ containerId }: RemoteLifecyleEventArgs): void =>
    mountReactComponent(
      <BrowserRouter>
        <CoreAuth userManagerSettings={environment.userManagerSettings}>
          <ClientProvider
            searchApiUrl={environment.searchApiUrl}
            tmsApiUrl={environment.tmsApiUrl}
          >
            <App />
          </ClientProvider>
        </CoreAuth>
      </BrowserRouter>,
      containerId
    ),
  mountRouter: ({
    containerId,
    history,
    createToken,
  }: RemoteLifecyleEventArgs): void =>
    mountReactComponent(
      <Router history={history}>
        <ClientProvider
          searchApiUrl={environment.searchApiUrl}
          tmsApiUrl={environment.tmsApiUrl}
          createToken={createToken}
        >
          <AppRouter isRootApp={false} />
        </ClientProvider>
      </Router>,
      containerId
    ),
  mountNavigation: ({
    containerId,
    history,
    isMenuOpen,
  }: RemoteLifecyleEventArgs): void =>
    mountReactComponent(
      <Router history={history}>
        <AppNavigation isMenuOpen={isMenuOpen} />
      </Router>,
      containerId
    ),
  unmountRouter: unmountReactComponent,
  unmountNavigation: unmountReactComponent,
};
