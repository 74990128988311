import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import Heading from '@amzn/meridian/heading';
import Row from '@amzn/meridian/row';
import {
  CoreTable,
  useAsyncState,
  ListResponse,
  CorePage,
} from '@amzn/dots-core-ui';
import { Configuration, useClient } from '../api';
import { ConfigurationColumnDefinitions } from './Configurations';
import { rootPath } from './routes';

const Home = (): JSX.Element => {
  const history = useHistory();
  const { tmsClient } = useClient();
  const fetch = useCallback(
    async (): Promise<ListResponse<Configuration>> =>
      tmsClient.configurations.getAll({
        packageType: 'test',
        isFavorite: true,
        page: 0,
        pageSize: 0,
      }),
    []
  );
  const [state] = useAsyncState(fetch);
  return (
    <CorePage
      header={
        <Heading level={1} type="d50">
          Favorites
        </Heading>
      }
    >
      <Row backgroundColor="secondary" spacingInset="small">
        <Heading level={2} type="h300">
          Favorite Test Suites
        </Heading>
      </Row>
      <CoreTable
        isLoading={state.status === 'pending'}
        columnDefinitions={ConfigurationColumnDefinitions}
        getItemId={(item: Configuration): number => item.id}
        items={state.data?.content}
        onRowClick={(item: Configuration): void =>
          history.push(
            `${rootPath}/packages/${item.packageId}/suites#${item.name}`
          )
        }
      />
    </CorePage>
  );
};
export default Home;
