import React from 'react';
import Link from '@amzn/meridian/link';
import { ConfigurationSearchFields } from '@amzn/dots-core-ui';
import { useHistory } from 'react-router-dom';
import { PackageType } from '../../api';
import { rootPath } from '../routes';

export type ConfigurationSearchLinkProps = {
  packageType: PackageType;
  filter: ConfigurationSearchFields;
  query: string;
};

const ConfigurationSearchLink = ({
  packageType,
  filter,
  query,
}: ConfigurationSearchLinkProps): JSX.Element => {
  const history = useHistory();
  return (
    <Link
      onClick={history.push}
      href={
        packageType === 'test'
          ? `${rootPath}/packages?q=${query}&filters=${filter}`
          : `${rootPath}/${packageType}s?q=${query}&filters=${filter}`
      }
    >
      {query}
    </Link>
  );
};

export default ConfigurationSearchLink;
