import { HttpClient, ListResponse } from '@amzn/dots-core-ui';
import { CreatorType, Creator } from '../models';

export interface CreatorApi {
  getAll: (type: CreatorType) => Promise<ListResponse<Creator>>;
}

export const creators = (client: HttpClient): CreatorApi => ({
  getAll: (type: CreatorType): Promise<ListResponse<Creator>> =>
    client.get({ url: `/creator/${type}` }),
});
