import React, { useState, useMemo, useCallback } from 'react';
import Button from '@amzn/meridian/button';
import Row from '@amzn/meridian/row';
import Input from '@amzn/meridian/input';
import Alert from '@amzn/meridian/alert';
import {
  CoreModal,
  CorePageLoader,
  useToggle,
  useFilter,
  useAsyncState,
} from '@amzn/dots-core-ui';
import { Package, useClient } from '../../api';
import { VersionDetail } from './VersionDetail';
import { VersionForm } from './VersionForm';

export type VersionDetailListProps = {
  p: Package;
};

export const VersionDetailList = ({
  p,
}: VersionDetailListProps): JSX.Element => {
  const { tmsClient } = useClient();
  const [state, fetch] = useAsyncState(
    useCallback(async () => {
      const result = await tmsClient.versions.getAll({ packageId: p.id });
      return result.content;
    }, [p.id])
  );

  const [isModalOpen, closeModal, openModal] = useToggle();
  const sortedVersions = useMemo(
    () => (state.data ? state.data.slice().sort((a, b) => b.id - a.id) : []),
    [state.data]
  );

  const [query, setQuery] = useState('');
  const filteredVersions = useFilter(sortedVersions, query, (c) => c.fileName);

  if (state.status === 'pending') {
    return <CorePageLoader />;
  }

  if (state.status === 'rejected') {
    return (
      <Alert size="medium" type="error">
        Sorry, an error occured while retriving the Versions.
      </Alert>
    );
  }

  return (
    <>
      <Row>
        <Input
          value={query}
          placeholder="Filter Versions..."
          onChange={setQuery}
        />
        <Button type="tertiary" onClick={openModal}>
          Add New
        </Button>
        <CoreModal
          title="Add a New Test Suite"
          isOpen={isModalOpen}
          onClose={closeModal}
        >
          <VersionForm
            sourceType={p.source}
            onCancel={closeModal}
            onSubmit={async (newVersion): Promise<void> => {
              if (newVersion.file) {
                await tmsClient.versions.createTmsVersion(
                  p.id,
                  newVersion?.file,
                  newVersion.description
                );
              } else if (newVersion.url) {
                await tmsClient.versions.createExternalVersion(
                  p.id,
                  newVersion.url,
                  newVersion.description
                );
              }
              closeModal();
              fetch();
            }}
          />
        </CoreModal>
      </Row>
      {!filteredVersions.length && query && (
        <Alert size="medium" type="informational">
          Sorry, no results for <b>{query}</b>...
        </Alert>
      )}
      {!filteredVersions.length && !query && (
        <Alert size="medium" type="informational">
          Hmm, looks like this Package does not have a Version yet...
          <Button size="small" type="link" onClick={openModal}>
            Try adding one!
          </Button>
        </Alert>
      )}
      {filteredVersions.map((version, i) => (
        <VersionDetail
          key={version.id}
          version={version}
          isLatestVersion={i === 0}
          isArchived={version.archived}
          onDelete={fetch}
          onArchive={fetch}
          onEdit={fetch}
        />
      ))}
    </>
  );
};
