import React, { useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import {
  CorePage,
  CoreModal,
  CoreTabGroupSwitch,
  CoreTabRoute,
  CoreAsync,
  useToaster,
  useToggle,
} from '@amzn/dots-core-ui';
import { TMSDetailHeader } from '../../components';
import { Configuration, useClient } from '../../api';
import {
  ConfigurationDetailList,
  ConfigurationSearchLink,
} from '../Configurations';
import { VersionDetailList } from '../Versions';
import { PackageProperties } from './PackageProperties';
import PackageForm from './PackageForm';
import PackageDeleteForm from './PackageDeleteForm';
import { rootPath } from '../routes';

const PackageDetail = (): JSX.Element => {
  const { id } = useParams<{ id: string }>();
  const parsedId = Number(id);
  const [isEditModalOpen, closeEditModal, openEditModal] = useToggle();
  const [isRemoveModalOpen, closeRemoveModal, openRemoveModal] = useToggle();
  const { tmsClient } = useClient();
  const { openToast } = useToaster();
  const history = useHistory();
  const getPackage = useCallback(async () => {
    const p = await tmsClient.packages.get(parsedId);
    let defaultConfig: Configuration | null = null;
    if (p.type !== 'test') {
      const packageConfigs = await tmsClient.configurations.getAll({
        packageId: parsedId,
      });
      [defaultConfig] = packageConfigs.content;
    }
    const { content } = await tmsClient.configurations.getAll({
      config: `.${p.name}.`,
    });
    return { package: p, usedBy: content, defaultConfig };
  }, [parsedId]);

  return (
    <CoreAsync action={getPackage}>
      {(data, fetch): JSX.Element => (
        <CorePage
          header={
            <TMSDetailHeader
              heading={data.defaultConfig ? data.defaultConfig.name : ''}
              subHeading={
                <>
                  {data.package.description && (
                    <Text type={data.defaultConfig ? 'b300' : 'b500'}>
                      {data.package.description}
                    </Text>
                  )}
                  {data.package.owner && (
                    <Row>
                      <Text>Owner:</Text>
                      <ConfigurationSearchLink
                        packageType={data.package.type}
                        filter="packageOwner"
                        query={data.package.owner}
                      />
                    </Row>
                  )}
                  {data.package.users && (
                    <Row>
                      <Text>Users:</Text>
                      <Row wrap="down" widths="fit" spacing="small">
                        {data.package.users.map((group) => (
                          <ConfigurationSearchLink
                            key={group}
                            packageType={data.package.type}
                            filter="packageUsers"
                            query={group}
                          />
                        ))}
                      </Row>
                    </Row>
                  )}
                </>
              }
              editEnabled={data.package.editable}
              onEditClick={openEditModal}
              deleteEnabled={data.package.deletable}
              onDeleteClick={openRemoveModal}
            />
          }
        >
          <CoreModal isOpen={isEditModalOpen} onClose={closeEditModal}>
            <PackageForm
              p={{
                ...data.package,
                name: data.defaultConfig?.name ?? data.package.name,
              }}
              onCancel={closeEditModal}
              onSubmit={async (updated): Promise<void> => {
                await tmsClient.packages.update(parsedId, updated);
                closeEditModal();
                fetch();
              }}
            />
          </CoreModal>
          <CoreModal
            title="Are you sure?"
            isOpen={isRemoveModalOpen}
            onClose={closeRemoveModal}
          >
            <PackageDeleteForm
              p={{
                ...data.package,
                name: data.defaultConfig?.name ?? data.package.name,
              }}
              onCancel={closeRemoveModal}
              onSubmit={async (): Promise<void> => {
                await tmsClient.packages.remove(parsedId);
                closeRemoveModal();
                openToast({
                  title: 'Success!',
                  message: `${data.package.name} has been deleted.`,
                  type: 'success',
                });
                fetch();
                history.push(rootPath);
              }}
            />
          </CoreModal>
          <CoreTabGroupSwitch>
            {data.package.type === 'test' && (
              <CoreTabRoute label="Test Suites" path="suites">
                <ConfigurationDetailList
                  p={data.package}
                  fetchPackage={fetch}
                />
              </CoreTabRoute>
            )}
            <CoreTabRoute label="Versions" path="versions">
              <VersionDetailList p={data.package} />
            </CoreTabRoute>
            <CoreTabRoute label="Details" path="details">
              <PackageProperties p={data.package} usedBy={data.usedBy} />
            </CoreTabRoute>
          </CoreTabGroupSwitch>
        </CorePage>
      )}
    </CoreAsync>
  );
};

export default PackageDetail;
