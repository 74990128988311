import React from 'react';
import Tag from '@amzn/meridian/tag';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import cogTokens from '@amzn/meridian-tokens/base/icon/cog';
import starTokens from '@amzn/meridian-tokens/base/icon/star';
import trashTokens from '@amzn/meridian-tokens/base/icon/trash';
import Text from '@amzn/meridian/text';
import Row from '@amzn/meridian/row';
import {
  CorePropertyDetail,
  CoreTile,
  CoreModal,
  CoreConfirm,
  useToggle,
} from '@amzn/dots-core-ui';
import { Configuration, useClient } from '../../api';
import { ConfigurationForm } from './ConfigurationForm';
import { ConfigurationText } from './ConfigurationText';

export type ConfigurationDetailProps = {
  configuration: Configuration;
  onDelete: () => void;
  onEdit: () => void;
  onFavorite: () => void;
};

export const ConfigurationDetail = ({
  configuration,
  onDelete,
  onEdit,
  onFavorite,
}: ConfigurationDetailProps): JSX.Element => {
  const [isEditModalOpen, closeEditModal, openEditModal] = useToggle();
  const [isRemoveModalOpen, closeRemoveModal, openRemoveModal] = useToggle();
  const [
    isFavoriteModalOpen,
    closeFavoriteModal,
    openFavoriteModal,
  ] = useToggle();
  const { tmsClient } = useClient();
  return (
    <CoreTile
      id={configuration.name}
      heading={`${configuration.name} | ${configuration.id}`}
      description={configuration.description}
      labelText={configuration.isDefault ? 'Default Config' : undefined}
      actions={
        <>
          <>
            <Button
              type={configuration.isFavorite ? 'primary' : 'secondary'}
              onClick={openFavoriteModal}
            >
              <Icon tokens={starTokens}>favorite</Icon>
            </Button>

            <CoreConfirm
              title={' '}
              isOpen={isFavoriteModalOpen}
              onCancel={closeFavoriteModal}
              onConfirm={async (): Promise<void> => {
                await tmsClient.configurations.update(configuration.id, {
                  ...configuration,
                  isFavorite: !configuration.isFavorite,
                });
                closeFavoriteModal();
                onFavorite();
              }}
            >
              <Text alignment="center">
                {configuration.isFavorite === true
                  ? 'Remove configuration from favorites ?'
                  : 'Add configuration to favorites ?'}
              </Text>
            </CoreConfirm>
          </>

          {configuration.editable && (
            <>
              <Button type="icon" onClick={openEditModal}>
                <Icon tokens={cogTokens}>Settings</Icon>
              </Button>
              <CoreModal
                title={`Edit ${configuration.name}`}
                isOpen={isEditModalOpen}
                onClose={closeEditModal}
              >
                <ConfigurationForm
                  configuration={configuration}
                  onCancel={closeEditModal}
                  onSubmit={async (edited): Promise<void> => {
                    await tmsClient.configurations.update(
                      configuration.id,
                      edited
                    );
                    closeEditModal();
                    onEdit();
                  }}
                />
              </CoreModal>
            </>
          )}

          {configuration.deletable && (
            <>
              <Button type="icon" onClick={openRemoveModal}>
                <Icon tokens={trashTokens}>Remove</Icon>
              </Button>
              <CoreConfirm
                title="Remove Test Suite"
                isOpen={isRemoveModalOpen}
                onCancel={closeRemoveModal}
                onConfirm={async (): Promise<void> => {
                  await tmsClient.configurations.remove(configuration.id);
                  onDelete();
                }}
              >
                <Text alignment="center">
                  Are you sure you want to remove{' '}
                  <strong>{configuration.name}</strong>?
                </Text>
              </CoreConfirm>
            </>
          )}
        </>
      }
    >
      <Row
        alignmentVertical="top"
        alignmentHorizontal="left"
        widths={['grid-6', 'grid-6']}
        spacing="small"
        spacingInset="none small"
      >
        <ConfigurationText
          label="Configuration"
          config={configuration.config}
        />
        <ConfigurationText
          label="Merged Configuration"
          config={configuration.mergedConfig}
        />
      </Row>
      {configuration.attributes?.tags && (
        <CorePropertyDetail label="tags">
          {configuration.attributes.tags.map((t) => (
            <Tag key={t}>{t}</Tag>
          ))}
        </CorePropertyDetail>
      )}
    </CoreTile>
  );
};
