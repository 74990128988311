import React, { useContext, useMemo } from 'react';
import {
  createSearchClient,
  SearchApi,
  TokenCreator,
  useAuth,
} from '@amzn/dots-core-ui';
import { createTmsClient, TmsApi } from './clients';

export type ClientContext = {
  searchClient: SearchApi;
  tmsClient: TmsApi;
};

export type ClientProviderProps = {
  children: React.ReactNode;
  createToken?: TokenCreator;
  searchApiUrl: string;
  tmsApiUrl: string;
};

const ClientContext = React.createContext<ClientContext | undefined>(undefined);

export const ClientProvider = ({
  children,
  createToken,
  searchApiUrl,
  tmsApiUrl,
}: ClientProviderProps): JSX.Element => {
  let ct = createToken;
  try {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    ct = useAuth().createToken;
  } catch (error) {
    // ignored
  }
  if (ct === undefined) {
    throw new Error('no Token Creator provided');
  }
  const tmsClient = createTmsClient(tmsApiUrl, createToken ?? ct);
  const searchClient = createSearchClient(searchApiUrl, createToken ?? ct);

  const value = useMemo(() => ({ tmsClient, searchClient }), [
    searchClient,
    tmsClient,
  ]);
  return (
    <ClientContext.Provider value={value}>{children}</ClientContext.Provider>
  );
};

export const useClient = (): ClientContext => {
  const context = useContext(ClientContext);
  if (context === undefined) {
    throw new Error('useClient must be used within a ClientProvider');
  }
  return context;
};
