import React, { useState, useCallback, useMemo } from 'react';
import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import Select, { SelectOption } from '@amzn/meridian/select';
import Button from '@amzn/meridian/button';
import Alert from '@amzn/meridian/alert';
import {
  CorePageLoader,
  useDebounce,
  CoreInput,
  AsyncState,
} from '@amzn/dots-core-ui';
import Loader from '@amzn/meridian/loader';
import { PlanItem, Configuration, Version, useClient } from '../../api';

type FormState = 'idle' | 'working' | 'error';

export type PlanItemFormProps = {
  planId: number;
  onSuccess: (newItem: PlanItem) => void;
  onCancel: () => void;
};

export const PlanItemForm = ({
  planId,
  onSuccess,
  onCancel,
}: PlanItemFormProps): JSX.Element => {
  const [configurationId, setConfigurationId] = useState<number | undefined>();
  const configurationError = useMemo(() => configurationId === undefined, [
    configurationId,
  ]);
  const [query, setQuery] = useState('');

  const [versionId, setVersionId] = useState(0);
  const [versions, setVersions] = useState<Version[]>([]);

  const [loop, setLoop] = useState(1);
  const loopError = useMemo(() => loop < 0, [loop]);
  const { tmsClient, searchClient } = useClient();

  const [searchState, setSearchState] = useState<AsyncState<Configuration[]>>({
    status: 'resolved',
    data: [],
  });
  const searchConfiguration = useCallback(async (): Promise<void> => {
    if (query === '') return;
    setSearchState({ status: 'pending' });
    const { results } = await searchClient(
      {
        model: 'config',
        // parameters: { fields: ['name'] },
      },
      query
    );
    if (results.length) {
      const { content } = await tmsClient.configurations.getAll({
        ids: results.slice(0, 200),
      });
      setSearchState({ status: 'resolved', data: content });
    } else {
      setSearchState({ status: 'resolved', data: [] });
    }
  }, [query]);

  useDebounce(searchConfiguration, 300, [query]);

  const onConfigurationChange = async (selectedId: number): Promise<void> => {
    setConfigurationId(selectedId);
    setVersions([]);
    const selectedConfiguration = searchState.data?.filter(
      (config) => config.id === selectedId
    )[0];
    const packageVersionsPage = await tmsClient.versions.getPage({
      packageId: selectedConfiguration?.packageId,
    });
    setVersions([
      ...packageVersionsPage.content.sort((a, b) =>
        b.version.localeCompare(a.version)
      ),
    ]);
  };
  // form state
  const [state, setState] = useState<FormState>('idle');
  const onSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    if (e) {
      e.preventDefault();
    }
    setState('working');
    try {
      const result = await tmsClient.plans.addItem(planId, {
        configurationId,
        versionId,
        loop,
      });
      setState('idle');
      onSuccess(result);
    } catch (error) {
      setState('error');
    }
  };
  if (state === 'error') {
    return (
      <Alert size="xlarge" type="error">
        Whoops, something went wrong...
      </Alert>
    );
  }
  return (
    <form onSubmit={onSubmit}>
      <Column spacingInset="xsmall" alignmentHorizontal="stretch">
        <Select
          label="Test Suite"
          value={configurationId}
          popoverMaxHeight={200}
          searchQuery={query}
          onChange={onConfigurationChange}
          onSearch={setQuery}
        >
          {searchState.data?.map((option) => (
            <SelectOption
              key={option.id}
              label={option.name}
              value={option.id}
            />
          ))}
          {(searchState.status === 'rejected' ||
            searchState.status === 'resolved') &&
            !searchState.data?.length && (
              <Column
                alignmentHorizontal="stretch"
                alignmentVertical="center"
                spacing="small"
                spacingInset="xlarge"
              >
                <Text alignment="center">No results</Text>
              </Column>
            )}
          {searchState.status === 'pending' && (
            <Column
              alignmentHorizontal="center"
              alignmentVertical="center"
              spacing="small"
              spacingInset="xlarge"
            >
              <CorePageLoader />
            </Column>
          )}
        </Select>
        {configurationError && (
          <Alert type="error" size="small">
            Test Suite is required
          </Alert>
        )}
        <Select
          disabled={configurationId === undefined}
          label="Version"
          value={versionId}
          popoverMaxHeight={200}
          searchQuery={query}
          onChange={setVersionId}
          onSearch={setQuery}
        >
          <SelectOption label="Latest Version" value={0} />
          {versions.map((option) => (
            <SelectOption
              key={option.id}
              label={`v${option.version} | ${option.fileName}`}
              value={option.id}
            />
          ))}
        </Select>
        <CoreInput
          errorMessage={loopError ? 'Loop is required' : ''}
          value={loop}
          onChange={setLoop}
          type="number"
          label="Loop"
        />
        <Row
          alignmentHorizontal="right"
          alignmentVertical="center"
          widths="fit"
        >
          <Button type="secondary" size="small" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            type="primary"
            size="small"
            disabled={configurationError || loopError}
            submit
          >
            {state === 'working' ? (
              <Loader type="circular" size="small" />
            ) : (
              'Confirm'
            )}
          </Button>
        </Row>
      </Column>
    </form>
  );
};
