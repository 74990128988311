import React from 'react';
import AppLayout from '@amzn/meridian/app-layout';
import AppNavigation from './AppNavigation';
import AppRouter from './AppRouter';

export default (): JSX.Element => {
  return (
    <AppLayout sidebarComponent={AppNavigation}>
      <AppNavigation isMenuOpen />
      <AppRouter isRootApp />
    </AppLayout>
  );
};
