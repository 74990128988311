import { UserManagerSettings } from 'oidc-client';
import {
  lassoProductionConfiguration,
  lassoStagingConfiguration,
} from '@amzn/dots-core-ui';

export interface Environment {
  hosts: string[];
  searchApiUrl: string;
  tmsApiUrl: string;
  userManagerSettings: UserManagerSettings;
}

const environments: Environment[] = [
  {
    hosts: [
      'localhost',
      'tms-beta.dots.lab126.a2z.com',
      'tools-beta.dots.lab126.a2z.com',
    ],
    searchApiUrl: 'https://search-beta.dots.lab126.a2z.com',
    tmsApiUrl: 'https://api.tms-beta.dots.lab126.a2z.com/api/v3',
    userManagerSettings: lassoStagingConfiguration,
  },
  {
    hosts: ['tms.dots.lab126.a2z.com', 'tools.dots.lab126.a2z.com'],
    searchApiUrl: 'https://search.dots.lab126.a2z.com',
    tmsApiUrl: 'https://api.tms.dots.lab126.a2z.com/api/v3',
    userManagerSettings: lassoProductionConfiguration,
  },
];

const match = environments.filter(
  (config) => config.hosts.indexOf(window.location.hostname) > -1
);
if (match.length === 0) {
  throw new Error(`${window.location.hostname} is not configured`);
}

export const [environment] = match;
