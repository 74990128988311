import React from 'react';
import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import {
  CoreProperties,
  CorePropertyRow,
  CorePropertyColumn,
  CorePropertyDetail,
} from '@amzn/dots-core-ui';
import Link from '@amzn/meridian/link';
import { useHistory } from 'react-router-dom';
import { Package, Configuration } from '../../api';
import { ConfigurationSearchLink } from '../Configurations';
import { rootPath } from '../routes';

const ConfigurationLinkColumn = ({
  references,
}: {
  references: Configuration[];
}): JSX.Element => {
  const history = useHistory();
  return (
    <Column spacingInset="small large">
      {references.map((config) => (
        <Link
          key={config.id}
          onClick={history.push}
          href={`${rootPath}/packages/${config.packageId}/suites#${config.name}`}
        >
          {config.name}
        </Link>
      ))}
    </Column>
  );
};

export type PackagePropertiesProps = {
  p: Package;
  usedBy: Configuration[];
};

export const PackageProperties = ({
  p,
  usedBy,
}: PackagePropertiesProps): JSX.Element => (
  <CoreProperties>
    <CorePropertyRow>
      <CorePropertyColumn title="Details">
        <CorePropertyDetail label="feature">
          <Text>{p.featureName}</Text>
        </CorePropertyDetail>
        <CorePropertyDetail label="source">
          <Text>{p.source}</Text>
        </CorePropertyDetail>
        {p.createdBy && (
          <CorePropertyDetail label="created by">
            <ConfigurationSearchLink
              packageType={p.type}
              filter="packageCreatedBy"
              query={p.createdBy}
            />
          </CorePropertyDetail>
        )}
      </CorePropertyColumn>
      <CorePropertyColumn title="Access">
        {p.owner && (
          <CorePropertyDetail label="owners">
            <ConfigurationSearchLink
              packageType={p.type}
              filter="packageOwner"
              query={p.owner}
            />
          </CorePropertyDetail>
        )}
        {p.users && (
          <CorePropertyDetail label="users">
            <Row wrap="down" widths="fit" spacing="small">
              {p.users.map((group) => (
                <ConfigurationSearchLink
                  key={group}
                  packageType={p.type}
                  filter="packageUsers"
                  query={group}
                />
              ))}
            </Row>
          </CorePropertyDetail>
        )}
      </CorePropertyColumn>
    </CorePropertyRow>
    {p.type === 'resource' && usedBy.length > 0 && (
      <CorePropertyRow>
        <CorePropertyColumn title="Referenced By">
          <Row widths="fit">
            <ConfigurationLinkColumn
              references={usedBy.slice(0, Math.ceil(usedBy.length / 2))}
            />
            <ConfigurationLinkColumn
              references={usedBy.slice(Math.ceil(usedBy.length / 2))}
            />
          </Row>
        </CorePropertyColumn>
      </CorePropertyRow>
    )}
  </CoreProperties>
);
