import React from 'react';
import { useQuery } from 'react-query';
import Button from '@amzn/meridian/button';
import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import Loader from '@amzn/meridian/loader';
import {
  CoreInput,
  CoreTextarea,
  CoreFilterableSelect,
  isNullOrWhitespace,
  useForm,
} from '@amzn/dots-core-ui';
import Alert from '@amzn/meridian/alert';
import { Configuration, useClient } from '../../api';

export type ConfigurationFormProps = {
  configuration?: Configuration;
  onSubmit: (configuration: Partial<Configuration>) => Promise<void> | void;
  onCancel: () => void;
};

export const ConfigurationForm = ({
  configuration,
  onSubmit,
  onCancel,
}: ConfigurationFormProps): JSX.Element => {
  const { tmsClient } = useClient();
  const {
    values,
    states,
    errors,
    handlers,
    hasErrors,
    handleSubmit,
    isSubmitting,
  } = useForm({
    name: {
      initialValue: configuration?.name ?? '',
      validate: async (value): Promise<string> => {
        if (isNullOrWhitespace(value)) {
          return 'Name is required';
        }
        if (value === configuration?.name) {
          return '';
        }
        const result = await tmsClient.configurations.getAll({ name: value });
        if (result.count > 0) {
          return `${value} is already in use`;
        }
        return '';
      },
    },
    config: {
      initialValue: configuration?.config ?? '',
    },
    description: { initialValue: configuration?.description ?? '' },
    tags: { initialValue: configuration?.attributes?.tags ?? [] },
  });

  const tags = useQuery('tags', async () => {
    const response = await tmsClient.attributes.getTags();
    return response.content.map((item) => item.name);
  });

  if (tags.isLoading) {
    return (
      <Column
        minHeight="100%"
        alignmentHorizontal="center"
        alignmentVertical="center"
      >
        <Loader size="large" type="circular" />
      </Column>
    );
  }

  if (tags.isError) {
    return (
      <Alert type="error" size="xlarge" title="Whoops, something went wrong">
        error
      </Alert>
    );
  }
  return (
    <form
      onSubmit={handleSubmit((vs) =>
        onSubmit({
          ...vs,
          attributes: {
            ...configuration?.attributes,
            tags: vs.tags,
          },
          isFavorite: configuration?.isFavorite ?? true,
        })
      )}
    >
      <Column spacingInset="xsmall" alignmentHorizontal="stretch">
        <CoreInput
          label="Name"
          value={values.name}
          errorMessage={errors.name}
          type="text"
          validationState={states.name}
          onChange={handlers.name}
          disabled={isSubmitting}
        />
        <CoreTextarea
          label="Configuration"
          value={values.config}
          errorMessage={errors.config}
          validationState={states.config}
          onChange={handlers.config}
          disabled={isSubmitting}
        />
        <CoreTextarea
          label="Description"
          value={values.description}
          onChange={handlers.description}
          disabled={isSubmitting}
        />
        <CoreFilterableSelect
          label="Tags"
          canAddItems
          items={tags.data ?? []}
          value={values.tags}
          onChange={handlers.tags}
          disabled={isSubmitting}
        />
        <Row
          alignmentHorizontal="right"
          alignmentVertical="center"
          widths="fit"
        >
          <Button type="secondary" size="small" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            type="primary"
            size="small"
            disabled={hasErrors || isSubmitting}
            submit
          >
            {isSubmitting ? <Loader type="circular" size="small" /> : 'Confirm'}
          </Button>
        </Row>
      </Column>
    </form>
  );
};
