import { HttpClient, PaginatedResponse } from '@amzn/dots-core-ui';
import { Configuration } from '../models';

export interface ConfigurationApi {
  getAll: (filters: {
    ids?: number[];
    name?: string;
    config?: string;
    packageCreatedBy?: string;
    packageId?: number;
    packageType?: string;
    page?: number;
    pageSize?: number;
    isFavorite?: boolean;
  }) => Promise<PaginatedResponse<Configuration>>;
  create: (
    packageId: number,
    configuration: Partial<Configuration>
  ) => Promise<Configuration>;
  update: (
    id: number,
    configuration: Partial<Configuration>
  ) => Promise<Configuration>;
  remove: (id: number) => Promise<void>;
}

export const configurations = (client: HttpClient): ConfigurationApi => ({
  getAll: (filters: {
    ids?: number[];
    name?: string;
    config?: string;
    packageCreatedBy?: string;
    packageId?: number;
    packageType?: string;
    page?: number;
    pageSize?: number;
    isFavorite?: boolean;
  }): Promise<PaginatedResponse<Configuration>> =>
    client.get({ url: '/configuration', payload: filters }),
  create: (
    packageId: number,
    configuration: Partial<Configuration>
  ): Promise<Configuration> =>
    client.post({
      url: '/configuration',
      payload: { ...configuration, packageId },
    }),
  update: (
    id: number,
    configuration: Partial<Configuration>
  ): Promise<Configuration> =>
    client.put({
      url: `/configuration/${id}`,
      payload: {
        ...configuration,
        id,
      },
    }),
  remove: (id: number): Promise<void> =>
    client.delete({ url: `/configuration/${id}` }),
});
