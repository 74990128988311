import React from 'react';
import Column from '@amzn/meridian/column';
import Heading from '@amzn/meridian/heading';
import Row from '@amzn/meridian/row';
import Button from '@amzn/meridian/button';
import Theme from '@amzn/meridian/theme';
import orangeLightTokens from '@amzn/meridian-tokens/theme/orange-light';

export type TMSDetailHeaderProps = {
  heading: React.ReactNode;
  subHeading: React.ReactNode;
  editEnabled: boolean;
  onEditClick?: () => void;
  deleteEnabled: boolean;
  onDeleteClick?: () => void;
};

export const TMSDetailHeader = ({
  heading,
  subHeading,
  editEnabled,
  onEditClick,
  deleteEnabled,
  onDeleteClick,
}: TMSDetailHeaderProps): JSX.Element => {
  return (
    <>
      <Heading level={2} type="d50">
        {heading}
      </Heading>
      <Row widths={['fill', 'fit']}>
        {subHeading && <Column spacing="xxsmall">{subHeading}</Column>}
        <Row alignmentHorizontal="right">
          {onEditClick && (
            <Button
              onClick={onEditClick}
              type="tertiary"
              disabled={!editEnabled}
            >
              Edit
            </Button>
          )}
        </Row>
        <Row alignmentHorizontal="right">
          {onDeleteClick && (
            <Theme tokens={orangeLightTokens}>
              <Button
                onClick={onDeleteClick}
                type="tertiary"
                disabled={!deleteEnabled}
              >
                Delete
              </Button>
            </Theme>
          )}
        </Row>
      </Row>
    </>
  );
};
