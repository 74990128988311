import React from 'react';
import Button from '@amzn/meridian/button';
import cogTokens from '@amzn/meridian-tokens/base/icon/cog';
import downloadTokens from '@amzn/meridian-tokens/base/icon/download-small';
import trashTokens from '@amzn/meridian-tokens/base/icon/trash';
import archiveTokens from '@amzn/meridian-tokens/base/icon/archive';
import Text from '@amzn/meridian/text';
import Badge from '@amzn/meridian/badge';
import {
  CorePropertyDetail,
  CoreTile,
  CoreConfirm,
  CoreModal,
  CoreDate,
  useToggle,
} from '@amzn/dots-core-ui';
import Icon from '@amzn/meridian/icon';
import { useClient, Version } from '../../api';
import { VersionForm } from './VersionForm';
import { VersionArchiveForm } from './VersionArchiveForm';

export type VersionDetailProps = {
  version: Version;
  isLatestVersion: boolean;
  isArchived: boolean;
  onEdit: () => void;
  onArchive: () => void;
  onDelete: () => void;
};

export const VersionDetail = ({
  version,
  isLatestVersion,
  isArchived,
  onEdit,
  onArchive,
  onDelete,
}: VersionDetailProps): JSX.Element => {
  const [isEditModalOpen, closeEditModal, openEditModal] = useToggle();
  const [isArchiveModalOpen, closeArchiveModal, openArchiveModal] = useToggle();
  const [isRemoveModalOpen, closeRemoveModal, openRemoveModal] = useToggle();
  const { tmsClient } = useClient();
  let label;
  if (isArchived) {
    label = 'Archived';
  } else if (isLatestVersion) {
    label = 'Latest Version';
  }
  return (
    <CoreTile
      heading={`${version.fileName} | ${version.version}`}
      description={
        <>
          {`${version.size} | `}
          <CoreDate date={version.created} />
        </>
      }
      labelText={label}
      actions={
        <>
          <Button
            href={tmsClient.versions.getDownloadUrl(version)}
            target="_blank"
            type="icon"
          >
            <Icon tokens={downloadTokens}>Download</Icon>
          </Button>
          {version.editable && (
            <>
              <Button type="icon" onClick={openEditModal}>
                <Icon tokens={cogTokens}>Settings</Icon>
              </Button>
              <CoreModal
                title={`Edit ${version.fileName}`}
                isOpen={isEditModalOpen}
                onClose={closeEditModal}
              >
                <VersionForm
                  version={version}
                  onCancel={closeEditModal}
                  onSubmit={async (updatedVersion): Promise<void> => {
                    await tmsClient.versions.update(version.id, updatedVersion);
                    closeEditModal();
                    onEdit();
                  }}
                />
              </CoreModal>
            </>
          )}
          <>
            <Button type="icon" onClick={openArchiveModal}>
              <Icon tokens={archiveTokens}>Archive</Icon>
            </Button>
            <CoreModal
              title={`${version.archived ? `Unarchive` : `Archive`} ${
                version.fileName
              }`}
              isOpen={isArchiveModalOpen}
              onClose={closeArchiveModal}
            >
              <VersionArchiveForm
                version={version}
                onCancel={closeArchiveModal}
                onSubmit={async (updatedVersion): Promise<void> => {
                  await tmsClient.versions.update(version.id, updatedVersion);
                  closeArchiveModal();
                  onArchive();
                }}
              />
            </CoreModal>
          </>
          {version.deletable && (
            <>
              <Button type="icon" onClick={openRemoveModal}>
                <Icon tokens={trashTokens}>Remove</Icon>
              </Button>
              <CoreConfirm
                title="Remove File Version"
                isOpen={isRemoveModalOpen}
                onCancel={closeRemoveModal}
                onConfirm={async (): Promise<void> => {
                  await tmsClient.versions.remove(version.id);
                  onDelete();
                }}
              >
                <Text alignment="center">
                  Are you sure you want to remove{' '}
                  <strong>{version.fileName}</strong>?
                </Text>
              </CoreConfirm>
            </>
          )}
        </>
      }
    >
      {version.packageCreatedBy && (
        <CorePropertyDetail label="uploaded by">
          <Text>{version.packageCreatedBy}</Text>
        </CorePropertyDetail>
      )}

      <CorePropertyDetail label="downloads">
        {version.numberDownloads && version.lastDownload ? (
          <Text>
            <Badge value={version.numberDownloads} type="theme" />
            as of <CoreDate date={version.lastDownload} />
          </Text>
        ) : (
          <Text>none</Text>
        )}
      </CorePropertyDetail>

      {version.description && (
        <CorePropertyDetail label="description">
          <Text>{version.description}</Text>
        </CorePropertyDetail>
      )}
    </CoreTile>
  );
};
