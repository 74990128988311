import {
  PaginatedResponse,
  ListResponse,
  HttpClient,
} from '@amzn/dots-core-ui';
import { Plan, PlanItem } from '../models';

export interface PlanApi {
  getAll: (filters: {
    ids?: number[];
    name?: string;
    page?: number;
    pageSize?: number;
  }) => Promise<PaginatedResponse<Plan>>;
  get: (id: number) => Promise<Plan>;
  create: (plan: Partial<Plan>) => Promise<Plan>;
  update: (id: number, plan: Partial<Plan>) => Promise<Plan>;
  getItems: (id: number) => Promise<ListResponse<PlanItem>>;
  addItem: (planId: number, item: Partial<PlanItem>) => Promise<PlanItem>;
  updateItem: (planId: number, item: Partial<PlanItem>) => Promise<PlanItem>;
  removeItem: (planId: number, itemId: number) => Promise<undefined>;
}

export const plans = (client: HttpClient): PlanApi => ({
  getAll: (filters: {
    ids?: number[];
    name?: string;
    page?: number;
    pageSize?: number;
  }): Promise<PaginatedResponse<Plan>> =>
    client.get({ url: '/plan', payload: filters }),
  get: (id: number): Promise<Plan> => client.get({ url: `/plan/${id}` }),
  create: (plan: Partial<Plan>): Promise<Plan> =>
    client.post({ url: '/plan', payload: plan }),
  update: (id: number, plan: Partial<Plan>): Promise<Plan> =>
    client.put({
      url: `/plan/${id}`,
      payload: {
        ...plan,
        id,
      },
    }),
  getItems: (id: number): Promise<ListResponse<PlanItem>> =>
    client.get({ url: `/plan/${id}/item`, payload: { page: 0, pageSize: 0 } }),
  addItem: (planId: number, item: Partial<PlanItem>): Promise<PlanItem> =>
    client.post({ url: `/plan/${planId}/item`, payload: { ...item, planId } }),
  updateItem: (planId: number, item: Partial<PlanItem>): Promise<PlanItem> =>
    client.post({
      url: `/plan/${planId}/item/${item.id}`,
      payload: { planId, ...item },
    }),
  removeItem: (planId: number, itemId: number): Promise<undefined> =>
    client.delete({ url: `/plan/${planId}/item/${itemId}` }),
});
