import React, { useState, useMemo, useCallback } from 'react';
import Alert from '@amzn/meridian/alert';
import Row from '@amzn/meridian/row';
import Button from '@amzn/meridian/button';
import Text from '@amzn/meridian/text';
import cogTokens from '@amzn/meridian-tokens/base/icon/cog';
import trashTokens from '@amzn/meridian-tokens/base/icon/trash';
import Input from '@amzn/meridian/input';
import {
  CoreModal,
  CorePageLoader,
  CoreScrollHandler,
  useToggle,
  useFilter,
  useAsyncState,
  CoreTile,
  isNullOrWhitespace,
  CoreConfirm,
  CoreTextarea,
  useForm,
} from '@amzn/dots-core-ui';
import Icon from '@amzn/meridian/icon';
import Column from '@amzn/meridian/column';
import Loader from '@amzn/meridian/loader';
import { Package, useClient } from '../../api';
import { ConfigurationDetail } from './ConfigurationDetail';
import { ConfigurationForm } from './ConfigurationForm';
import { ConfigurationText } from './ConfigurationText';

export type ConfigurationDetailListProps = {
  p: Package;
  fetchPackage: () => void;
};

export const ConfigurationDetailList = ({
  p,
  fetchPackage,
}: ConfigurationDetailListProps): JSX.Element => {
  const { tmsClient } = useClient();
  const getConfigurations = useCallback(async () => {
    const result = await tmsClient.configurations.getAll({
      packageId: p.id,
      page: 0,
      pageSize: 0,
    });
    return result.content;
  }, [p.id]);
  const hasSharedConfig = useMemo(() => !isNullOrWhitespace(p.config), [
    p.config,
  ]);
  const [state, fetch] = useAsyncState(getConfigurations);

  const [isModalOpen, closeModal, openModal] = useToggle();
  const [
    isSharedConfigEditModalOpen,
    closeSharedConfigEditModal,
    openSharedConfigEditModal,
  ] = useToggle();
  const [
    isSharedConfigDeleteModalOpen,
    closeSharedConfigDeleteModal,
    openSharedConfigDeleteModal,
  ] = useToggle();
  const sharedForm = useForm({ config: { initialValue: p.config } });

  const sortedConfigurations = useMemo(
    () =>
      state.data
        ? state.data.slice().sort((a, b) =>
            // eslint-disable-next-line no-nested-ternary
            a.isDefault === b.isDefault ? a.id - b.id : a.isDefault ? -1 : 1
          )
        : [],
    [state]
  );

  const [query, setQuery] = useState('');
  const filteredConfigurations = useFilter(sortedConfigurations, query, [
    (c): string => c.name,
    (c): string => c.config,
    (c): string => c.mergedConfig,
  ]);

  if (state.status === 'pending') {
    return <CorePageLoader />;
  }

  if (state.status === 'rejected') {
    return (
      <Alert size="medium" type="error">
        Sorry, an error occured while retriving the Test Suites.
      </Alert>
    );
  }
  return (
    <CoreScrollHandler>
      <Row type="fill" spacingInset="small">
        <Input
          value={query}
          placeholder="Filter Test Suites..."
          onChange={setQuery}
        />
        <Button onClick={openModal}>Add New</Button>
        {!hasSharedConfig && (
          <Button type="tertiary" onClick={openSharedConfigEditModal}>
            Add Shared Config
          </Button>
        )}
        <CoreModal
          title="Edit Shared Configuration"
          isOpen={isSharedConfigEditModalOpen}
          onClose={closeSharedConfigEditModal}
        >
          <form
            onSubmit={sharedForm.handleSubmit(async ({ config }) => {
              await tmsClient.packages.update(p.id, { config });
              closeSharedConfigEditModal();
              fetchPackage();
            })}
          >
            <Column spacingInset="xsmall" alignmentHorizontal="stretch">
              <CoreTextarea
                label="Shared Configuration"
                value={sharedForm.values.config}
                onChange={sharedForm.handlers.config}
              />
            </Column>
            <Row
              alignmentHorizontal="right"
              alignmentVertical="center"
              widths="fit"
            >
              <Button
                type="secondary"
                size="small"
                onClick={closeSharedConfigEditModal}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                size="small"
                disabled={sharedForm.isSubmitting}
                submit
              >
                {sharedForm.isSubmitting ? (
                  <Loader type="circular" size="small" />
                ) : (
                  'Confirm'
                )}
              </Button>
            </Row>
          </form>
        </CoreModal>
        <CoreModal
          title="Add a New Test Suite"
          isOpen={isModalOpen}
          onClose={closeModal}
        >
          <ConfigurationForm
            onCancel={closeModal}
            onSubmit={async (newConfiguration): Promise<void> => {
              await tmsClient.configurations.create(p.id, newConfiguration);
              closeModal();
              fetch();
            }}
          />
        </CoreModal>
      </Row>
      {hasSharedConfig && (
        <CoreTile
          heading="Shared Configuration"
          description="Merged with each Test Suite below to create the Merged
              Configuration"
          type="fill"
          actions={
            <>
              <Button type="icon" onClick={openSharedConfigEditModal}>
                <Icon tokens={cogTokens}>Settings</Icon>
              </Button>
              <Button type="icon" onClick={openSharedConfigDeleteModal}>
                <Icon tokens={trashTokens}>Remove</Icon>
              </Button>
              <CoreConfirm
                title="Remove Shared Configuration"
                isOpen={isSharedConfigDeleteModalOpen}
                onCancel={closeSharedConfigDeleteModal}
                onConfirm={async (): Promise<void> => {
                  await tmsClient.packages.update(p.id, { config: '' });
                  closeSharedConfigDeleteModal();
                  fetchPackage();
                }}
              >
                <Text alignment="center">
                  Are you sure you want to remove the Shared Configuration? This
                  will remove this configuration for all Test Suites
                </Text>
              </CoreConfirm>
            </>
          }
        >
          <Row spacing="small" spacingInset="none small" widths="fill">
            <ConfigurationText label="Configuration" config={p.config} split />
          </Row>
        </CoreTile>
      )}
      {!filteredConfigurations.length && query && (
        <Alert size="medium" type="informational">
          Sorry, no results for <b>{query}</b>...
        </Alert>
      )}
      {!filteredConfigurations.length && !query && (
        <Alert size="medium" type="informational">
          Hmm, looks like this Package does not have a Test Suite yet...
          <Button size="small" type="link" onClick={openModal}>
            Try adding one!
          </Button>
        </Alert>
      )}
      {filteredConfigurations.map((configuration) => (
        <ConfigurationDetail
          key={configuration.id}
          configuration={configuration}
          onDelete={fetch}
          onEdit={fetch}
          onFavorite={fetch}
        />
      ))}
    </CoreScrollHandler>
  );
};
