import React from 'react';
import Button from '@amzn/meridian/button';
import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import Loader from '@amzn/meridian/loader';
import { useForm } from '@amzn/dots-core-ui';
import { SourceType, Version } from '../../api';

export type VersionArchiveFormProps = {
  sourceType?: SourceType;
  version?: Version;
  onSubmit: (fields: { archived: boolean }) => void;
  onCancel: () => void;
};

export const VersionArchiveForm = ({
  version,
  onSubmit,
  onCancel,
}: VersionArchiveFormProps): JSX.Element => {
  const { handleSubmit, hasErrors, isSubmitting } = useForm({
    archived: {
      initialValue: version?.archived ?? true,
    },
  });

  return (
    <form
      onSubmit={handleSubmit(() =>
        onSubmit({ archived: !version?.archived ?? true })
      )}
    >
      <Column spacingInset="xsmall" alignmentHorizontal="stretch">
        <Text alignment="center">Are you sure you want to proceed?</Text>
        <Row
          alignmentHorizontal="right"
          alignmentVertical="center"
          widths="fit"
        >
          <Button type="secondary" size="small" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            type="primary"
            size="small"
            disabled={hasErrors || isSubmitting}
            submit
          >
            {isSubmitting ? <Loader type="circular" size="small" /> : 'Confirm'}
          </Button>
        </Row>
      </Column>
    </form>
  );
};
