import React from 'react';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import Text from '@amzn/meridian/text';
import closeTokens from '@amzn/meridian-tokens/base/icon/close';
import chevronUpSmallTokens from '@amzn/meridian-tokens/base/icon/chevron-up-small';
import chevronDownSmallTokens from '@amzn/meridian-tokens/base/icon/chevron-down-small';
import { CoreConfirm, useToggle } from '@amzn/dots-core-ui';
import { PlanItem, Plan } from '../../api';

export type ActionCellProps = {
  plan: Plan;
  item: PlanItem;
  editable: boolean;
  onConfirmDelete: () => void;
  onOrderChange: (delta: 1 | -1) => void;
  maxOrder: number;
  minOrder: number;
};

export const PlanItemActionCell = ({
  item,
  editable = true,
  onConfirmDelete,
  onOrderChange,
  maxOrder,
  minOrder,
}: ActionCellProps): JSX.Element => {
  const [isOpen, close, open] = useToggle();
  return (
    <>
      <Button
        type="icon"
        disabled={item.order === minOrder || !editable}
        onClick={(): void => onOrderChange(-1)}
      >
        <Icon tokens={chevronUpSmallTokens} />
      </Button>
      <Button
        type="icon"
        disabled={item.order === maxOrder || !editable}
        onClick={(): void => onOrderChange(1)}
      >
        <Icon tokens={chevronDownSmallTokens} />
      </Button>
      <Button type="icon" disabled={!editable} onClick={open}>
        <Icon tokens={closeTokens}>Remove</Icon>
      </Button>
      <CoreConfirm
        title="Remove Test Suite"
        isOpen={isOpen}
        onCancel={close}
        onConfirm={(): void => {
          close();
          onConfirmDelete();
        }}
      >
        <Text alignment="center">
          Are you sure you want to remove
          <br />
          <strong>{item.configurationName}</strong>?
        </Text>
      </CoreConfirm>
    </>
  );
};
