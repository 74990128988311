import { ColumnDefinition } from '@amzn/dots-core-ui';
import { Plan } from '../../api';

const PlanColumnDefinitions: ColumnDefinition<Plan>[] = [
  { id: 'id', header: 'ID', item: (plan): number => plan.id },
  {
    id: 'name',
    header: 'Name',
    item: (plan): string => plan.name,
  },
  {
    id: 'owner',
    header: 'Owner',
    item: (plan): string => plan.owner,
  },
];

export default PlanColumnDefinitions;
