import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  CoreModal,
  useToggle,
  planSearchFields,
  ListResponse,
  TmsSearchScope,
  CoreListPage,
} from '@amzn/dots-core-ui';
import { PlanForm } from './PlanForm';
import { Plan, useClient } from '../../api';
import PlanColumnDefinitions from './PlanColumnDefinitions';

const PlanList = (): JSX.Element => {
  const history = useHistory();
  const { path } = useRouteMatch();
  const [isModalOpen, closeModal, openModal] = useToggle();
  const { tmsClient, searchClient } = useClient();
  const scope: TmsSearchScope = { model: 'plan' };
  const resolver = (ids: number[]): Promise<ListResponse<Plan>> =>
    tmsClient.plans.getAll({
      ids,
      page: 0,
      pageSize: 0,
    });
  return (
    <>
      <CoreModal
        title="Add a New Plan"
        isOpen={isModalOpen}
        onClose={closeModal}
      >
        <PlanForm
          onCancel={closeModal}
          onSubmit={async (newPlan): Promise<void> => {
            const result = await tmsClient.plans.create(newPlan);
            history.push(`${path}/${result.id}/suites`);
          }}
        />
      </CoreModal>
      <CoreListPage
        title="Test Plans"
        columnDefinitions={PlanColumnDefinitions}
        getItemId={(item: Plan): number => item.id}
        filters={planSearchFields}
        scope={scope}
        searchClient={searchClient}
        resolver={resolver}
        onAddButtonClick={openModal}
        onRowClick={(item: Plan): void =>
          history.push(`${path}/${item.id}/suites`)
        }
      />
    </>
  );
};

export default PlanList;
