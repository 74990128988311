import React from 'react';
import Button from '@amzn/meridian/button';
import Link from '@amzn/meridian/link';
import { TableActionBar } from '@amzn/meridian/table';
import { CoreTable, CoreModal, useToggle } from '@amzn/dots-core-ui';
import { useHistory } from 'react-router-dom';
import { PlanItem, Plan, PlanType, useClient } from '../../api';
import { PlanItemForm } from './PlanItemForm';
import { PlanItemActionCell } from './PlanItemActionCell';
import { rootPath } from '../routes';

export type PlanItemListProps = {
  items: PlanItem[];
  plan: Plan;
  planType: PlanType;
  onUpdate: () => void;
};
export const PlanItemList = ({
  items,
  plan,
  planType,
  onUpdate,
}: PlanItemListProps): JSX.Element => {
  const history = useHistory();
  const [isAddItemModalOpen, closeAddItemModal, openAddItemModal] = useToggle();
  const { tmsClient } = useClient();
  const minOrder = Math.min(...items.map((i) => i.order));
  const maxOrder = Math.max(...items.map((i) => i.order));
  const columnDefinitions = [
    {
      id: 'order',
      header: 'Order',
      item: (item: PlanItem): number => item.order,
    },
    {
      id: 'loop',
      header: 'Loop',
      item: (item: PlanItem): number => item.loop,
    },
    {
      id: 'testsuite',
      header: 'Test Suite Name',
      item: (item: PlanItem): JSX.Element => (
        <Link
          onClick={history.push}
          href={`${rootPath}/packages/${item.packageId}/suites#${item.configurationName}`}
        >
          {item.configurationName}
        </Link>
      ),
    },
    {
      id: 'version',
      header: 'Version',
      item: (item: PlanItem): string =>
        item.versionId
          ? `v${item.versionVersion} | ${item.versionFileName}`
          : 'Latest Version',
    },
    {
      id: 'actions',
      header: 'Actions',
      item: (item: PlanItem): JSX.Element => (
        <PlanItemActionCell
          item={item}
          editable={plan.editable}
          plan={plan}
          minOrder={minOrder}
          maxOrder={maxOrder}
          onConfirmDelete={async (): Promise<void> => {
            await tmsClient.plans.removeItem(plan.id, item.id);
            onUpdate();
          }}
          onOrderChange={async (delta): Promise<void> => {
            const currentOrder = item.order;
            const peer = items[items.indexOf(item) + delta];
            await Promise.all([
              tmsClient.plans.updateItem(plan.id, {
                ...item,
                order: currentOrder + delta,
              }),
              tmsClient.plans.updateItem(plan.id, {
                ...peer,
                order: currentOrder,
              }),
            ]);
            onUpdate();
          }}
        />
      ),
    },
  ];
  return (
    <>
      {planType === 'static' && (
        <TableActionBar>
          <Button disabled={!plan.editable} onClick={openAddItemModal}>
            Add New
          </Button>
          <CoreModal
            title="Add a New Item"
            isOpen={isAddItemModalOpen}
            onClose={closeAddItemModal}
          >
            <PlanItemForm
              planId={plan.id}
              onCancel={closeAddItemModal}
              onSuccess={(): void => {
                closeAddItemModal();
                onUpdate();
              }}
            />
          </CoreModal>
        </TableActionBar>
      )}
      <CoreTable
        columnDefinitions={columnDefinitions.filter(
          (cd) =>
            planType === 'static' ||
            (planType === 'dynamic' &&
              ['actions', 'order'].indexOf(cd.id) === -1)
        )}
        getItemId={(item: PlanItem): number => item.id}
        items={items}
      />
    </>
  );
};
