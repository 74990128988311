import { HttpClient, ListResponse } from '@amzn/dots-core-ui';
import { AttributeKey, AttributeValue, Tag } from '../models';

export interface AttributeApi {
  getKeys: () => Promise<ListResponse<AttributeKey>>;
  getValues: (attributeName: string) => Promise<ListResponse<AttributeValue>>;
  getTags: () => Promise<ListResponse<Tag>>;
}

export const attributes = (client: HttpClient): AttributeApi => ({
  getKeys: (): Promise<ListResponse<AttributeKey>> =>
    client.get({ url: `/attribute` }),
  getValues: (attributeName: string): Promise<ListResponse<AttributeValue>> =>
    client.get({ url: `/attribute/${attributeName}` }),
  getTags: (): Promise<ListResponse<Tag>> => client.get({ url: '/tag' }),
});
