import React from 'react';
import { css } from 'emotion';
import ColorTokens from '@amzn/meridian-tokens/base/color';
import Column from '@amzn/meridian/column';
import Text from '@amzn/meridian/text';
import Row from '@amzn/meridian/row';
import { CoreCopyButton } from '@amzn/dots-core-ui';

const bottomBorderStyle = css({
  borderBottom: `1px solid ${ColorTokens.colorGray200}`,
});

const wordWrapStyle = css({
  wordBreak: 'break-all',
});

export type ConfigurationProps = {
  label: string;
  config: string;
  split?: boolean;
};

export const ConfigurationText = ({
  label,
  config,
  split = false,
}: ConfigurationProps): JSX.Element => {
  const lines = config.split('\n');
  const col1 = split ? lines.slice(0, Math.ceil(lines.length / 2)) : lines;
  const col2 = lines.slice(Math.ceil(lines.length / 2));
  return (
    <Column spacing="xxsmall">
      <Row className={bottomBorderStyle}>
        <Text>
          <b>{label}</b>
        </Text>
        <CoreCopyButton text={config} />
      </Row>
      <Row
        widths={split ? 'grid-6' : 'fill'}
        wrap="down"
        alignmentVertical="top"
        alignmentHorizontal="left"
      >
        <Column
          spacing="none"
          alignmentVertical="top"
          alignmentHorizontal="left"
        >
          {col1?.map((line) => (
            <Text key={line} type="b200" className={wordWrapStyle}>
              {line}
            </Text>
          ))}
        </Column>
        {split && (
          <Column
            spacing="none"
            alignmentVertical="top"
            alignmentHorizontal="left"
          >
            {col2?.map((line) => (
              <Text key={line} type="b200" className={wordWrapStyle}>
                {line}
              </Text>
            ))}
          </Column>
        )}
      </Row>
    </Column>
  );
};
