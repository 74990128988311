import { HttpClient, ListResponse } from '@amzn/dots-core-ui';
import { Source } from '../models';

export interface SourceApi {
  getAll: () => Promise<ListResponse<Source>>;
}

export const sources = (client: HttpClient): SourceApi => ({
  getAll: (): Promise<ListResponse<Source>> => client.get({ url: '/source' }),
});
