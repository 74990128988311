import { HttpClient, ListResponse } from '@amzn/dots-core-ui';
import { Feature } from '../models';

export interface FeatureApi {
  getAll: () => Promise<ListResponse<Feature>>;
}

export const features = (client: HttpClient): FeatureApi => ({
  getAll: (): Promise<ListResponse<Feature>> =>
    client.get({ url: '/feature?page=0&pageSize=0' }),
});
